// react imports
import { useState, useEffect } from "react";

// hook imports
import useWindowWidth from "./useWindowWidth";

function useSidebar(sidebarCollapseWidth = 1024) {

    // set initial sidebar state
    const [isSidebar, setIsSidebar] = useState(false);

    // get window width
    const { windowWidth } = useWindowWidth();

    // collapse sidebar if windowWidth >= sidebarCollapseWidth
    useEffect(() => {
        if (windowWidth >= sidebarCollapseWidth && isSidebar) setIsSidebar(false);
    }, [windowWidth, isSidebar]);

    function toggleSidebar() {
        setIsSidebar((state) => !state);
    }

    return { isSidebar, toggleSidebar };
}

export default useSidebar;