// react imports
import React from "react";
import { Link } from "react-router-dom";

// snippet imports
import GetCurrentPath from "../snippets/GetCurrentPath";

function NavbarBreadcrumbs() {

    const { path, location } = GetCurrentPath();

    return (
        <div className="hidden lg:block border border-blueDark text-sm">
            <Link to="/" className="font-lb text-blueLight hover:underline">
                thepeski
            </Link>
            {(path.length > 0 && location.pathname !== "/") && <span className="mx-1">/</span>}
            {path.map((item, index) => {
                return (
                    <>
                        <Link to={`/${path.slice(0, index + 1).join("/")}`} key={index} className={`hover:underline`}>
                            {item}
                        </Link>
                        {index !== path.length - 1 && <span className="mx-1">/</span>}
                    </>
                );
            })}
        </div>
    );
}

export default NavbarBreadcrumbs;