// react imports
import { useState, useEffect } from "react";

function useWindowWidth() {

    // set initial window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // update window width
    function handleWindowWidth() {
        setWindowWidth(window.innerWidth);
    }

    // run once when component mounts
    useEffect(() => {

        // listen for window resize and run handleWindowWidth
        window.addEventListener("resize", handleWindowWidth);

        // remove event listener when component unmounts
        return () => window.removeEventListener("resize", handleWindowWidth);
    }, []);

    return { windowWidth };
}

export default useWindowWidth;