// react imports
import React from "react";
import { Link } from "react-router-dom";

// icons imports
import { ReactComponent as DarkModeToggle } from "../assets/icons/DarkMode.svg";

function NavbarRight() {
    return (
        <div className="flex ml-5 border border-black">

            {/* search */}
            <div className="">
                <Link to="/finance/-corporate-finance/dividend-discount-model/something-which-is-explained-here" className="flex items-center">path</Link>
                {/* <Link to="/" className="flex items-center">home</Link> */}
            </div>

            {/* toggle */}
            <DarkModeToggle className="w-7 h-7 ml-5" />

        </div>
    );
}

export default NavbarRight;