// react imports
import { useState, useEffect } from "react";

function useDarkMode() {

    // check if dark mode is enabled
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const darkModePreference = localStorage.getItem("theme"); // check if preference is saved in localStorage
        return darkModePreference === "dark" ? true : darkModePreference === "light" ? false : true; // default to dark mode
    });

    // run onde when component mounts
    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)"); // get current color preference
        const handleChange = (e) => setIsDarkMode(e.matches); // trigger setDarkMode when color preference changes

        darkModeMediaQuery.addEventListener("change", handleChange); // listen for changes
        return () => darkModeMediaQuery.removeEventListener("change", handleChange); // stop listening when component unmounts
    }, []); // run effect only once

    // run when isDarkMode changes
    useEffect(() => {
        if (isDarkMode) localStorage.setItem("theme", "dark");
        else localStorage.setItem("theme", "light");

        document.body.setAttribute("data-theme", isDarkMode ? "dark" : "light"); // set theme attribute on body
    }, [isDarkMode]); // run effect when isDarkMode changes

    const toggleDarkMode = () => setIsDarkMode((mode) => !mode); // toggle dark mode

    // return state and setter
    return { isDarkMode, toggleDarkMode };
}

export default useDarkMode;