// react imports
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// hooks imports
import useDarkMode from "./hooks/useDarkMode";

// components imports
import Navbar from "./components/Navbar";

function App() {

    // use dark mode hook
    const { isDarkMode, toggleDarkMode } = useDarkMode();

    return (
        <Router>
            <Navbar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
            <Routes>
            </Routes>
        </Router>
    );
}

export default App;