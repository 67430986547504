// react imports
import React from "react";
import { Link } from "react-router-dom";

// snippet imports
import GetCurrentPath from "../snippets/GetCurrentPath";

// icons imports
import { ReactComponent as Burger } from "../assets/icons/Burger.svg";

function NavbarSidebar({ isSidebar, toggleSidebar }) {

    const { path, location } = GetCurrentPath();

    return (
        <div className={`${isSidebar ? "fixed" : "hidden"} w-[100vw] max-w-[640px] h-auto p-5 border-b border-r border-borderLight bg-bgLight shadow-lg`}>
            <Burger className="w-7 min-w-7 h-7 min-h-7 mt-[1px] fill-blueDark cursor-pointer" onClick={toggleSidebar} />
            <div>
                <Link to="/" className="block font-lb mt-5 border-2 border-borderLight hover:border-blueDark rounded-lg p-2 text-blueLight">
                    thepeski
                </Link>
                {location.pathname !== "/" ? (path.map((item, index) => {
                    return (
                        <div>
                            <Link to={`/${path.slice(0, index + 1).join("/")}`} key={index} className={`block mt-5 border-2 border-borderLight hover:border-blueDark rounded-lg p-2`}>
                                {item}
                            </Link>
                        </div>
                    );
                })) : null}
            </div>
        </div>
    );
}

export default NavbarSidebar;