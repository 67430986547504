// react imports
import React from "react";
import { Link } from "react-router-dom";

// components imports
import NavbarBreadcrumbs from "./NavbarBreadcrumbs";

// icons imports
import { ReactComponent as Burger } from "../assets/icons/Burger.svg";

function NavbarLeft({ toggleSidebar }) {

    return (
        <div className="flex items-center">
            <NavbarBreadcrumbs />
            <Burger className="lg:hidden w-7 min-w-7 h-7 min-h-7 fill-grayDark hover:fill-grayLight cursor-pointer" onClick={toggleSidebar} />
            <Link to="/" className="lg:hidden ml-5 font-lb text-blueLight hover:underline">thepeski</Link>
        </div>

    );
}

export default NavbarLeft;