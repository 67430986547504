// react imports
import { React, useEffect } from "react";

// hooks imports
import useSidebar from "../hooks/useSidebar";

// components imports
import NavbarLeft from "./NavbarLeft";
import NavbarRight from "./NavbarRight";
import NavbarSidebar from "./NavbarSidebar";

// icons imports
import { ReactComponent as Burger } from "../assets/icons/Burger.svg";

function Navbar({ isDarkMode, toggleDarkMode }) {

    const { isSidebar, toggleSidebar } = useSidebar(1024);
    
    return (
        <>
            {/* navbar wrapper */}
            <div className="fixed top-0 w-full shadow-lg">

                {/* container */}
                <div className="flex justify-between max-w-[1400px] p-5 mx-auto">
                    <NavbarLeft toggleSidebar={toggleSidebar} />
                    <NavbarRight />
                </div>

            </div>

            <NavbarSidebar isSidebar={isSidebar} toggleSidebar={toggleSidebar} />
        </>
    );
}

export default Navbar;