// react imports
import { useLocation } from "react-router-dom";

function GetCurrentPath() {
    const location = useLocation();
    return {
        path: location.pathname.split("/").slice(1),
        location: location
    };
}

export default GetCurrentPath;